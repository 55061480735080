<template>
  <v-app>
    <v-container>
      <v-row justify="center">
        <v-col cols="12" md="8" lg="6">
          <v-card>
            <v-card-title primary-title>
              <div>
                <div class="text-h5">
                  Cookies Disabled
                </div>
              </div>
            </v-card-title>
            <v-card-text>
              It seems like you disabled cookies in your browser settings. This application needs cookies to work properly. Please enable them in your browser settings.
            </v-card-text>
            <v-card-actions>
              <v-spacer/>
              <v-btn @click="reload">
                Reload
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
  export default {

    created () {
      setInterval (() => {
        if (navigator.cookieEnabled) {
          location.reload ()
        }
      }, 5000)
    },
    methods: {
      reload () {
        location.reload ()
      }
    }
  }
</script>
